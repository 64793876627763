import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';

import css from './SectionFeaturesStyle2.module.css';
import { Button } from '../../../../components';

const LockIcon = () => {
  return (
    <svg width={32} height={40} viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1466 14.824C15.5659 14.7968 13.9851 14.8195 12.3999 14.8149L10.3831 14.824C9.58361 14.7968 8.78415 14.8104 7.9847 14.8195H6.24045C5.16845 14.8104 4.09646 14.8149 3.02447 14.8195C2.17051 14.8195 1.66177 15.3191 1.66177 16.1822C1.66177 23.1638 1.66177 30.1453 1.65723 37.1269C1.65723 38.0127 2.35675 38.5032 3.02447 38.5032C11.6595 38.4851 20.299 38.4896 28.934 38.4896C29.8606 38.4896 30.3512 37.999 30.3512 37.0724C30.3512 30.1272 30.3512 23.1819 30.3512 16.2367C30.3512 15.2919 29.8742 14.8195 28.934 14.8195H17.1511L17.1466 14.824Z"
        stroke="#D7FF5A"
        strokeWidth="1.64433"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M24.7279 14.6018C24.7279 13.1301 24.7279 11.6584 24.7279 10.1821C24.7279 8.42878 24.3736 6.789 23.4333 5.26731C22.1342 3.15967 20.2855 1.83331 17.9098 1.30639C14.6621 0.588705 11.8549 1.52897 9.57919 3.91824C8.1756 5.3945 7.42157 7.18872 7.32619 9.25094C7.23988 11.0315 7.31256 12.8167 7.28076 14.5973"
        stroke="#D7FF5A"
        strokeWidth="1.64433"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21.0169 14.6016C20.9987 14.3654 20.9669 14.1292 20.9669 13.893C20.9624 12.5666 20.9261 11.2357 20.9715 9.90937C21.0532 7.59278 19.3953 5.417 17.0878 4.95822C15.3163 4.60392 13.7446 5.01727 12.4455 6.35726C11.4916 7.34295 11.0238 8.48762 11.0328 9.85486C11.0465 11.4356 11.0328 13.0163 11.0328 14.6016"
        stroke="#D7FF5A"
        strokeWidth="1.64433"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.4552 24.1996C18.4552 24.9536 18.2054 25.6032 17.574 26.0438C17.2969 26.2391 17.247 26.4617 17.247 26.7615C17.2561 27.7244 17.2561 28.6874 17.247 29.6504C17.247 30.4589 16.7564 30.9586 15.9933 30.9586C15.2302 30.9586 14.7441 30.468 14.7396 29.6504C14.7351 28.6692 14.7396 27.6881 14.7396 26.707C14.7396 26.4617 14.7123 26.28 14.4761 26.0983C13.0544 24.999 13.1362 22.5825 15.1302 21.8149C16.6655 21.2244 18.537 22.3191 18.4507 24.1451"
        stroke="#D7FF5A"
        strokeWidth="1.64433"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

const IdCardIcon = () => {
  return (
    <svg width={40} height={30} viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.35211"
        y="0.883485"
        width="37.2953"
        height="27.31"
        rx="5.16743"
        stroke="#D7FF5A"
        strokeWidth="1.64759"
        fill="none"
      />
      <path
        d="M18.5363 22.028H6.48557C5.89523 22.028 5.44105 21.4808 5.53282 20.8746C5.94901 18.1324 7.82463 15.8947 10.3068 15.048C9.11973 14.2762 8.33431 12.9 8.34882 11.3334C8.37102 8.99641 10.2022 7.08365 12.4501 7.05037C14.7765 7.0162 16.6726 8.96623 16.6726 11.3769C16.6726 12.9253 15.8906 14.2833 14.7146 15.048C17.1968 15.8947 19.0724 18.1324 19.4886 20.8746C19.5808 21.4808 19.1262 22.028 18.5358 22.028H18.5363Z"
        stroke="#D7FF5A"
        strokeWidth="1.64759"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <line
        x1="22.4961"
        y1="11.2186"
        x2="34.4785"
        y2="11.2186"
        stroke="#D7FF5A"
        strokeWidth="1.64759"
        fill="none"
      />
      <line
        x1="22.4961"
        y1="14.2141"
        x2="34.4785"
        y2="14.2141"
        stroke="#D7FF5A"
        strokeWidth="1.64759"
        fill="none"
      />
      <line
        x1="22.4961"
        y1="17.2095"
        x2="34.4785"
        y2="17.2095"
        stroke="#D7FF5A"
        strokeWidth="1.64759"
        fill="none"
      />
    </svg>
  );
};

const BoxIcon = () => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25291 18.2742C5.25291 21.4953 5.27267 24.7124 5.2371 27.9334C5.2292 28.6962 5.38333 29.1151 6.01173 29.4076C8.94031 30.779 11.8373 32.2532 14.7738 33.585C15.2796 33.8143 15.8132 34.0554 16.2716 34.332C17.6154 35.1422 18.8761 35.0948 20.2317 34.4308C23.7176 32.7195 27.2192 31.0517 30.7288 29.4076C31.3651 29.1112 31.3848 28.5816 31.3848 27.9374C31.3769 24.7163 31.3848 21.4992 31.3848 18.2782"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M30.1795 10.868C29.2824 9.86412 28.073 9.92736 27.0019 9.57956C24.2196 8.67846 21.4017 7.9315 18.5877 7.18848C18.1846 7.08177 18.0304 6.82093 17.8249 6.5008C16.6709 4.68279 15.505 2.87268 14.351 1.06653C10.0628 2.20081 5.79444 3.33114 1.38379 4.50099C2.4825 6.84859 4.07129 8.64289 5.14628 10.868"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.2642 14.4404C18.4973 21.2303 18.3155 28.0241 18.3748 34.814"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.3745 14.8351C19.6352 15.6098 20.2676 17.1432 20.9394 18.4514C21.8643 20.2536 22.7377 20.5382 24.4055 19.9335C27.5159 18.8031 30.729 18.0997 33.8987 17.2144C34.2821 17.1077 34.6852 17.0405 35.116 16.5741C33.9461 14.76 32.7763 12.946 31.6025 11.1319C31.2863 10.6458 30.6618 10.6577 30.4168 10.7841C27.8163 12.12 24.9667 12.2148 22.3069 13.2345C21.6034 13.5032 20.813 13.4756 20.1332 13.7997C18.0701 14.7838 16.1691 13.5546 14.2286 13.1989C11.9798 12.7879 9.77843 11.9935 7.54939 11.4165C7.24507 11.3374 7.03956 11.1398 6.78662 10.9976"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.0465 14.97C16.8332 16.1991 16.0625 17.867 15.11 19.349C14.5567 20.2067 14.0983 20.4438 13.1181 20.1948C10.4939 19.5269 7.92493 18.5942 5.27301 18.0448C4.04387 17.7919 2.70013 17.4717 1.40381 16.6734C2.6448 14.7803 3.85417 12.9662 5.02402 11.1206C5.1742 10.8834 5.30462 10.9071 5.47457 10.8676C7.90122 10.2748 10.2607 9.34602 12.6834 8.70576C14.4816 8.2315 16.3273 7.89951 18.0465 7.03003"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.4814 7.02998C19.6592 4.87604 21.0346 2.9197 22.3862 1.08588C26.7494 2.25178 30.9783 3.3821 35.2111 4.51243"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.375 7.29504V14.1758"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M34.9894 4.78131C34.4045 6.44914 33.2662 7.65456 32.4758 9.14849C32.1636 9.73737 31.6024 10.1128 31.4917 10.8677"
        stroke="#D7FF5A"
        strokeWidth="1.44292"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeaturesStyle2 = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={classNames(className, css.root)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <h2 className={css.sectionTitle}>{title.content}</h2>
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field
            data={callToAction}
            className={classNames(defaultClasses.ctaButton, css.ctaBtn)}
            options={fieldOptions}
          />
        </header>
      ) : null}
      {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, css.featuresMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <div className={css.block}>
            <LockIcon />
            <h3>{blocks?.[0]?.title?.content}</h3>
          </div>
          <div className={css.block}>
            <IdCardIcon />
            <h3>{blocks?.[1]?.title?.content}</h3>
          </div>
          <div className={css.block}>
            <BoxIcon />
            <h3>{blocks?.[2]?.title?.content}</h3>
          </div>
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesStyle2.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesStyle2.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesStyle2;
