import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionFeaturesStyle1.module.css';
import { NamedLink } from '../../../../components';

const BoxIcon = () => {
  return (
    <svg width={50} height={50} viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.94782 26.8854C6.94782 31.5908 6.97669 36.2904 6.92473 40.9958C6.91319 42.11 7.13835 42.722 8.05633 43.1493C12.3345 45.1527 16.5664 47.3062 20.8561 49.2518C21.5951 49.5867 22.3746 49.9389 23.0443 50.343C25.0073 51.5266 26.849 51.4573 28.8293 50.4874C33.9215 47.9874 39.0368 45.551 44.1637 43.1493C45.0932 42.7163 45.1221 41.9426 45.1221 41.0015C45.1105 36.2961 45.1221 31.5965 45.1221 26.8912"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M43.361 16.0659C42.0504 14.5994 40.2837 14.6918 38.7191 14.1838C34.6546 12.8674 30.5381 11.7762 26.4274 10.6908C25.8385 10.5349 25.6133 10.1539 25.3131 9.68621C23.6272 7.03041 21.924 4.38616 20.2382 1.74768C13.974 3.40467 7.73861 5.05588 1.29541 6.76483C2.90044 10.1943 5.22138 12.8154 6.79176 16.0659"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M25.9541 21.285C26.2947 31.2039 26.0292 41.1285 26.1158 51.0473"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M26.1158 21.8624C27.9576 22.994 28.8813 25.2342 29.8628 27.1452C31.2138 29.7779 32.4897 30.1936 34.9262 29.3102C39.4699 27.659 44.1637 26.6313 48.794 25.3381C49.3541 25.1822 49.943 25.084 50.5723 24.4028C48.8633 21.7527 47.1544 19.1027 45.4397 16.4527C44.9778 15.7426 44.0656 15.7599 43.7076 15.9446C39.9087 17.8961 35.746 18.0346 31.8604 19.5242C30.8328 19.9168 29.6781 19.8764 28.685 20.3498C25.6713 21.7874 22.8942 19.9918 20.0594 19.4722C16.7743 18.8718 13.5585 17.7113 10.3023 16.8684C9.85771 16.7529 9.55749 16.4642 9.18799 16.2564"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M25.6363 22.0588C23.8639 23.8543 22.738 26.2907 21.3466 28.4558C20.5383 29.7086 19.8686 30.055 18.4368 29.6913C14.6032 28.7156 10.8505 27.353 6.97645 26.5505C5.1809 26.181 3.21792 25.7134 1.32422 24.5471C3.13709 21.7816 4.90377 19.1316 6.61272 16.4354C6.83211 16.089 7.02263 16.1236 7.27089 16.0659C10.8158 15.1999 14.2626 13.8431 17.8017 12.9078C20.4287 12.215 23.1249 11.73 25.6363 10.4598"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M26.2715 10.4598C27.992 7.31326 30.0012 4.45538 31.9757 1.77649C38.3496 3.47967 44.5272 5.13088 50.7106 6.7821"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M26.1157 10.8467V20.8983"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M50.3874 7.17468C49.5329 9.61109 47.8701 11.372 46.7154 13.5544C46.2593 14.4146 45.4395 14.9631 45.2778 16.0658"
        stroke="black"
        strokeWidth="2.19265"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeaturesStyle1 = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    appearance,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName, css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, css.featuresMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            rootClassName={css.blockImage}
            ctaButtonClass={defaultClasses.ctaButton}
            blocks={[blocks[0]]}
            sectionId={sectionId}
            responsiveImageSizes="(max-width: 767px) 100vw, 568px"
            options={options}
          />
          <div className={css.blockContent}>
            <h2>
              {blocks?.[1]?.title?.content} <BoxIcon />
            </h2>
            <p>{blocks?.[1]?.text?.content}</p>
            <div className={css.actions}>
              <NamedLink className={css.ctaBtn} name="SearchPage">Store</NamedLink>
              <NamedLink className={css.ctaBtn} name="CMSPage" params={{ pageId: 'hosting-guide' }}>Host</NamedLink>
              <NamedLink className={css.ctaBtn} name="CMSPage" params={{ pageId: 'find-out-more' }}>Find out more</NamedLink>
            </div>
          </div>
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesStyle1.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesStyle1.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesStyle1;
