import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';

import SectionFeaturesDefault from './SectionFeaturesDefault';
import SectionFeaturesWithHeroBlock from './SectionFeaturesWithHeroBlock';
import SectionFeaturesStyle1 from './SectionFeaturesStyle1';
import SectionFeaturesStyle2 from './SectionFeaturesStyle2';
import SectionFeaturesStyle3 from './SectionFeaturesStyle3';
import SectionFeaturesStyle4 from './SectionFeaturesStyle4';
import SectionFeaturesStyle5 from './SectionFeaturesStyle5';
import SectionFeaturesStyle6 from './SectionFeaturesStyle6';
import SectionFeaturesStyle7 from './SectionFeaturesStyle7';
import SectionFeaturesStyle8 from './SectionFeaturesStyle8';
import SectionFeaturesStyle9 from './SectionFeaturesStyle9';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionFeaturesCustom = props => {
  const { sectionName } = props;

  if (/^hero-block/.test(sectionName)) {
    return <SectionFeaturesWithHeroBlock {...props} />;
  }

  if (/^style1/.test(sectionName)) {
    return <SectionFeaturesStyle1 {...props} />;
  }

  if (/^style2/.test(sectionName)) {
    return <SectionFeaturesStyle2 {...props} />;
  }

  if (/^style3/.test(sectionName)) {
    return <SectionFeaturesStyle3 {...props} />;
  }

  if (/^style4/.test(sectionName)) {
    return <SectionFeaturesStyle4 {...props} />;
  }

  if (/^style5/.test(sectionName)) {
    return <SectionFeaturesStyle5 {...props} />;
  }

  if (/^style6/.test(sectionName)) {
    return <SectionFeaturesStyle6 {...props} />;
  }

  if (/^style7/.test(sectionName)) {
    return <SectionFeaturesStyle7 {...props} />;
  }

  if (/^style8/.test(sectionName)) {
    return <SectionFeaturesStyle8 {...props} />;
  }

  if (/^style9/.test(sectionName)) {
    return <SectionFeaturesStyle9 {...props} />;
  }

  return <SectionFeaturesDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesCustom;
